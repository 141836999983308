import React, { useState } from 'react'
import styled from 'styled-components'
import { SCREEN } from 'styles/screens'
import { Button } from 'components/styled/button'

const Main = styled.div`
  // margin-bottom: 100px;
  overflow-x: hidden;

  .product-design {
    border-radius: 0 0 20px 20px;
  }

  .frontend {
  }

  .line {
    padding: 0;
    margin: 0;
    width: 1300px;
    margin-left: 150px;
  }
`

const Course = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 50px;
  width: 1300px;
  height: 250px;
  margin-left: 150px;
  // box-shadow: 0px 3px 20px 0px #0800001F;
  border-radius: 20px 20px 20px 20px;
  position: relative;
  background-color: white;
  // box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.2), 4px 0px 10px rgba(0, 0, 0, 0.2), -4px 0px 10px rgba(0, 0, 0, 0.2);


  .for-mobile {
    .responsive {
      display: none;

      @media only screen and (max-width: ${SCREEN.MOBILE}) {
        display: flex;
        margin: 0px;
        font-size: 8px;
        opacity: 0.5;
        padding: 5px;
        width: 80px;
        height: max-content;
        margin-bottom: 5px;
      }
    }

    @media only screen and (max-width: ${SCREEN.MOBILE}) {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: ${SCREEN.MOBILE}) {
      flex-direction: column;
      align-items: baseline;

    }
  }


  .Image {
    margin-left: 30px;
    width: 180px;
    height: 180px;

    @media only screen and (max-width: ${SCREEN.MOBILE}) {
      margin-left: 300px;
      width: 100px;
      height: 100px;
    }
  }


  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
    width: auto;
    column-gap: 20px;
    height: max-content;
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 500px;

  .title {
    a {
      font-size: 26px;
      font-weight: 700;
      color: #000;
      text-decoration: none;

      @media only screen and (max-width: ${SCREEN.MOBILE}) {
        font-size: 19px;
        width: 200px;
      }

      @media only screen and (max-width: ${SCREEN.MOBILE}) {
        line-height: 1.3;
      }
      :hover {
        text-decoration: underline;
      }
    }

    @media only screen and (max-width: ${SCREEN.MOBILE}) {
      margin-top: 20px;
      width: 35%;
    }
  }

  .description {
    color: #676767;
    font-size: 14px;
    font-weight: 400;
    width: 700px;

    @media only screen and (max-width: ${SCREEN.MOBILE}) {
      font-size: 13px;
      width: 200px;
    }

    .description-content {
      cursor: pointer;
      width: 800px;
      height: 100px;
    }

`

const YellowContainer = styled.div`
  background-color: #ECFB70;
  width: max-content;
  height: 40px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  .tag {
    padding: 20px;

    @media only screen and (max-width: ${SCREEN.MOBILE}) {
      padding: 0px;
      width: 70px;
    }
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    display: none;
  }
`
const EnrollButton = styled(Button)`
  height: 50px;
  margin-left: 340px;

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    margin-left: 0px;
    height: 30px;
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
`


const CourseITem = ({ course, index, maxIndex, enrolled, onEnroll }) => {

  const [isExpanded, setIsExpanded] = useState(false)

  const symbolsCount = { maxCount: 100 }

  const isEnrolled = enrolled
  const [isHovered, setIsHovered] = useState(false)

  const handleClick = () => {
    onEnroll(course)
  }


  return (
    <>
      <Course style={{
        borderRadius: index == 0 ? '20px 20px 0px 0px' : (index == maxIndex ? '0px 0px 20px 20px' : '0 0 0 0'),
        marginBottom: index == maxIndex ? '100px' : '0',
        boxShadow: index != maxIndex && index != 0 ? '0px 4px 10px rgba(0, 0, 0, 0.2)' : index == maxIndex ? '0px 4px 10px rgba(0, 0, 0, 0.2)' : '0px 0px 10px rgba(0, 0, 0, 0.2)',
      }}>
        <div className='for-mobile'>
          <YellowContainer className='responsive'>
          <span className='tag'>{course?.data?.hashtags.map(element =>
                      '#' + element + ', ',
                    )}</span>
          </YellowContainer>
          <img src={`https://d34rq7yialevbd.cloudfront.net/${course?.data?.image}`} className='Image' />
        </div>
        <div className='container'>
          <Info>
            <div className='title'><a href={`/program/${course?.key}`}>{course?.data?.title}</a></div>
            <div className='description'><span className='description-content' onClick={() => {
              setIsExpanded(!isExpanded)
            }}>{isExpanded ? course?.data?.description1 : `${course?.data?.description1.substring(0, 100)}...`}</span>
            </div>
            <YellowContainer>
                    <span className='tag'>{course?.data?.hashtags.map(element =>
                      '#' + element + ', ',
                    )}</span>
            </YellowContainer>
          </Info>
          {course?.data?.enableEnrollment &&
          <EnrollButton
            className='enroll'
            onClick={handleClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{

              backgroundColor: isEnrolled ? '#B7B7B7' : (isHovered ? 'transparent' : '#ECFB70'),
              border: isEnrolled ? 'none' : '3px solid #ECFB70',
              transition: 'background-color 0.3s',
            }}
          >
            {isEnrolled ? 'UNENROLL' : 'ENROLL'}
          </EnrollButton>
          }
        </div>
      </Course>
      <hr className='line' style={{ display: index == maxIndex ? 'none' : 'flex' }}></hr>

    </>
  )
}

export default CourseITem
